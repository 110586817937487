import React, { useContext, useState } from "react";
import Cookies from "js-cookie";
import { BASE_URL } from "../config/constants";
import axios from "axios";
import AuthModal from "./AuthModal";
import { MyContext } from "../context/credentialContext";
import toast from "react-hot-toast";

// Product data structure
const sizes = ["S", "M", "L"];
const colors = ["Black", "White", "Red", "Blue", "Green"];

export default function ProductDetails({ details }) {
  const { contextValue, setContextValue } = useContext(MyContext);
  const [selectedSize, setSelectedSize] = useState(sizes[0]);
  const [selectedColor, setSelectedColor] = useState(colors[0]);
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const handleSubmit = (data, formState) => {
    if (formState === "register") {
      // Registration flow
      const url = `${BASE_URL}/auth/signup`;
      axios
        .post(url, data)
        .then((response) => {
          const resData = response.data;

          if (resData.status === "FAILED") {
            toast.error(resData.message);
            return;
          }

          // Set user information in cookies
          Cookies.set("userId", resData.data.id);
          Cookies.set("name", resData.data.fullname);
          Cookies.set("email", resData.data.email);

          // Add to cart function
          addToCart();
        })
        .catch((error) => toast.error("Signup failed. Please try again later"));
    } else {
      // Login flow
      const url = `${BASE_URL}/auth/login`;
      axios
        .post(url, data)
        .then((response) => {
          const resData = response.data;

          if (resData.status === "FAILED") {
            toast.error(resData.message);
            return;
          }

          // Set user information in cookies
          Cookies.set("userId", resData.data.id);
          Cookies.set("name", resData.data.fullname);
          Cookies.set("email", resData.data.email);

          // Add to cart function
          addToCart();
        })
        .catch((error) => toast.error("Login failed. Please try again later"));
    }
  };

  const handleCart = () => {
    if (Cookies.get("userId") == undefined) {
      setOpen(true);
    } else {
      addToCart();
    }
  };

  const addToCart = () => {
    setOpen(false);

    let userId = Cookies.get("userId");
    const url = `${BASE_URL}/cart`;

    axios
      .post(url, {
        prod_id: details.id,
        user_id: userId,
        qty: quantity,
        size: selectedSize,
        color: selectedColor,
      })
      .then((response) => {
        const result = response.data;
        const { status, message } = result;

        if (status == "SUCCESS") {
          toast.success(message);
          setContextValue(contextValue + 1);
        } else {
          toast.error(message);
        }
      })
      .catch((error) => {
        toast.error("Error adding to cart");
      });
  };

  return (
    <div className="container mx-auto my-10 flex flex-col md:flex-row">
      {/* Product Image Section */}
      <div className="md:w-1/2 p-5">
        <img
          src={`https://admin.stunnerrave.com/images/product_images/medium/${details.main_image}`}
          alt={details.product_name}
          className="w-full h-auto"
        />
      </div>

      {/* Product Details Section */}
      <div className="md:w-1/2 p-5">
        <h1 className="text-3xl font-bold mb-4 font-Neuehaasdisplay tracking-[2px]">
          {details.product_name}
        </h1>
        <p className="text-2xl font-semibold text-cream mb-4">
          ₦{details.price.toLocaleString()}
        </p>

        <h3 className="font-semibold text-lg">Description:</h3>
        <p className="text-sm font-semibold text-grey mb-4">
          {details.description}
        </p>
        {/* Size Options */}
        <div className="mb-4">
          <h3 className="font-semibold text-lg">Select Size:</h3>
          <select
            value={selectedSize}
            onChange={(e) => setSelectedSize(e.target.value)}
            className="border mt-2 p-2 px-4 bg-black rounded-sm"
          >
            {sizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>

        {/* Color Options */}
        <div className="mb-4">
          <h3 className="font-semibold text-lg">Select Color:</h3>
          <select
            value={selectedColor}
            onChange={(e) => setSelectedColor(e.target.value)}
            className="border mt-2 p-2 px-4 bg-black rounded-sm"
          >
            {colors.map((color) => (
              <option key={color} value={color}>
                {color}
              </option>
            ))}
          </select>
        </div>

        {/* Quantity Selector */}
        <div className="mb-4">
          <h3 className="font-semibold text-lg">Quantity:</h3>
          <input
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            min="1"
            className="border mt-2 p-2 bg-black text-white w-20"
          />
        </div>

        {/* Add to Cart Button */}
        <button
          className="bg-cream text-black px-4 py-2 rounded-md mt-4"
          onClick={() => handleCart()}
        >
          Add to Cart
        </button>
      </div>

      <AuthModal
        open={open}
        setOpen={() => setOpen(!open)}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}
