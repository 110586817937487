import React from "react";
import st from "../imgs/st.jpg";

export default function AboutUs() {
  return (
    <div className="border-t border-border-subtle">
      <div className="flex flex-col md:flex-row py-5">
        {/* Left section */}
        <div className="mx-5  md:w-1/2">
          <h1 className="uppercase font-Neuehaasdisplay">
            Wh<span className="font-dirtyline">a</span>t W
            <span className="font-dirtyline">e</span> D
            <span className="font-dirtyline">o</span>
          </h1>
          <p className="font-Neuehaasdisplay font-light tracking-[1px] w-full md:w-[400px] max-w-full break-words">
            Stunner Rave creates unforgettable rave experiences, powered by
            Nigeria's vibrant energy and culture. We host high-energy events
            that bring people together, from intimate parties to large-scale
            raves, all with a unique Nigerian twist.
          </p>
          <div className="h-[250px] w-full max-w-full md:w-[400px] mt-10">
            <img src={st} alt="st" className="h-full w-full object-cover" />
          </div>
        </div>

        {/* Right section */}
        <div className="space-y-10 mt-10 md:mt-40  md:w-auto mx-5">
          {/* Vision section */}
          <div>
            <h1 className="uppercase font-Neuehaasdisplay">
              O<span className="font-dirtyline">u</span>r V
              <span className="font-dirtyline">i</span>si
              <span className="font-dirtyline">o</span>n
            </h1>
            <p className="w-full max-w-full font-Neuehaasdisplay font-light tracking-[1px]">
              Our vision is to lead the global rave scene, showcasing the
              vibrancy of Nigerian culture. We aim to inspire a worldwide
              movement, making Stunner Rave synonymous with unforgettable,
              boundary-pushing events.
            </p>
          </div>

          {/* Mission section */}
          <div>
            <h1 className="font-Neuehaasdisplay uppercase">
              O<span className="font-dirtyline">u</span>r Mi
              <span className="font-dirtyline">s</span>si
              <span className="font-dirtyline">o</span>n
            </h1>
            <p className="font-Neuehaasdisplay font-light tracking-[1px] w-full  max-w-full">
              Our mission is to elevate Nigeria’s party culture through
              world-class rave events. We strive to innovate and create
              experiences that entertain, unite, and set new standards in the
              entertainment industry.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
