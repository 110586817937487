import React, { useEffect } from "react";
import Header from "../components/Header";
import ContactPage from "../components/ContactPage";
import Footer from "../components/Footer";

export default function Contact() {
  useEffect(() => {
    document.title = "Contact Us";
  }, []);

  // Update document title on component mount
  return (
    <>
      <Header />
      <ContactPage />
      <Footer />
    </>
  );
}
