import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import right from "../svg/rightarrow.svg";
import left from "../svg/leftarrow.svg";

const RaveSection = () => {
  return (
    <div className="md:h-[300px] h-[200px] w-full bg-white flex flex-col justify-between items-center text-center overflow-hidden border border-border-subtle">
      {/* Top Rolling Marquee */}
      <motion.div
        className="w-full whitespace-nowrap font-Neuehaasdisplay text-5xl md:text-7xl font-bold uppercase text-black"
        animate={{ x: ["100%", "-100%"] }}
        transition={{
          duration: 15,
          repeat: Infinity,
          ease: "linear",
        }}
      >
        The l<span className="font-dirtyline">o</span>udest r
        <span className="font-dirtyline">a</span>ve in Calabar The l
        <span className="font-dirtyline">o</span>udest r
        <span className="font-dirtyline">a</span>ve in Calabar The l
        <span className="font-dirtyline">o</span>ude
        <span className="font-dirtyline">s</span>t r
        <span className="font-dirtyline">a</span>ve in Calabar
      </motion.div>

      <div className="text-xl md:text-5xl font-medium my-10 text-black flex items-center">
        <img src={right} alt="right arrow" className="w-7 md:w-12" />
        <Link
          to="/tickets"
          className="font-Neuehaasdisplay hover:text-orange-200"
        >
          GET E<span className="font-dirtyline">A</span>RLY BIRD TICKETS HE
          <span className="font-dirtyline">R</span>E
        </Link>
        <img src={left} alt="right arrow" className="w-7 md:w-12" />
      </div>

      <motion.div
        className="w-full whitespace-nowrap font-Neuehaasdisplay text-5xl md:text-7xl font-bold uppercase text-black flex space-x-2"
        animate={{ x: ["100%", "-100%"] }}
        transition={{
          duration: 20,
          repeat: Infinity,
          ease: "linear",
        }}
      >
        NOVEMBER NOVEMBER NOVEMBER NOVEMBER
      </motion.div>
    </div>
  );
};

export default RaveSection;
