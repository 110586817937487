import React, { useEffect, useRef } from "react";
import vid from "../vid/stunnerave.mp4";
import Marque from "./Marque";

export default function Carousel() {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      // Ensure the video is muted before playing
      videoRef.current.muted = true;
      videoRef.current.play().catch((error) => {
        console.log("Autoplay prevented:", error);
        // Optionally, you can handle the error by showing a play button or some fallback UI
      });
    }
  }, []);
  return (
    <>
      <div className="md:h-[84vh] h-[60%] w-full">
        <video
          ref={videoRef}
          autoPlay
          loop
          mute
          playsInline
          src={vid}
          className=" object-cover h-full w-full"
        ></video>
      </div>
      <Marque child={"WHO&nbsp;WE&nbsp;ARE"} number={10} />
    </>
  );
}
