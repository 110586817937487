import React from "react";
import { useState } from "react";
import st from "../imgs/st.jpg";
import { BASE_URL } from "../config/constants";
import axios from "axios";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";

export default function Contact() {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const url = `${BASE_URL}/user/contact-us`;

    axios
      .post(url, formData)
      .then((response) => {
        const result = response.data;
        const { status, message } = result;

        if (status == "SUCCESS") {
          toast.success(message);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(
          "An error occured, please check your connection and try again"
        );
      });
  };

  return (
    <div className="flex flex-col md:flex-row space-x-5">
      <img src={st} alt="" className="md:w-1/2 md:h-1/2 md:mt-10" />
      <div className="bg-black text-white  flex flex-col ">
        <h1 className="md:text-6xl mt-7 mb-7 font-light text-3xl md:mt-[200px] font-Neuehaasdisplay">
          Talk To Us
        </h1>
        <p className="md:text-lg text-sm mb-10 font-Neuehaasdisplay font-light font-[15px]">
          Connect with us. Drop us a mail to{" "}
          <span className="font-bold">admin@stunnerave.com</span>, engage with
          us on
          <span className="font-bold">@stunnerrave</span>, or simply fill out
          the form below. Your questions, feedback, or just a friendly hello are
          always welcome.
        </p>

        <form
          className="mx-2 font-Neuehaasdisplay font-light tracking-[1px]"
          onSubmit={sendEmail}
        >
          <div className="mb-6">
            <label className="block text-sm font-light mb-1" htmlFor="name">
              Name
            </label>
            <input
              className="w-full px-4 py-2 border border-gray-300 text-black"
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-6">
            <label className="block text-sm font-light mb-1" htmlFor="email">
              Email Address
            </label>
            <input
              className="w-full px-4 py-2 border border-gray-300 text-black"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-6">
            <label className="block text-sm font-light mb-1" htmlFor="message">
              Message
            </label>
            <textarea
              className="w-full px-4 py-2 border border-gray-300 text-black"
              id="message"
              name="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>

          <button
            className="bg-cream font-Neuehaasdisplay text-black font-bold py-2 px-4 w-full hover:bg-gray-200"
            type="submit"
          >
            {isLoading ? (
              <CircularProgress size={20} color="black" />
            ) : (
              "Send Email"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
