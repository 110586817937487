import React from "react";
import arrowdown from "../imgs/arrow-down.svg";

export default function Marque({ child, number }) {
  return (
    <div className="h-[45px] bg-[#fefeba] flex items-center overflow-hidden">
      {/* Container for animated elements */}
      <div className="relative w-full flex space-x-5 tracking-wider font-Neuehaasdisplay animate-scroll-down mx-2 h-[22.5px]">
        {[...Array(number)].map((_, index) => (
          <div key={index} className="flex space-x-4 items-center opacity-100">
            <div
              className="text-xs font-light text-black "
              dangerouslySetInnerHTML={{ __html: `${child}` }}
            ></div>
            <img src={arrowdown} alt="arrow-down svg" className="w-4 h-3" />
          </div>
        ))}
      </div>
    </div>
  );
}
