import React, { useEffect, useState } from "react";
import { usePaystackPayment } from "react-paystack";
import Cookies from "js-cookie";

import time from "../svg/time.svg";
import loc from "../svg/location.svg";
import { BASE_URL } from "../config/constants";
import axios from "axios";
import AuthModal from "./AuthModal";
import toast from "react-hot-toast";

const TicketPage = () => {
  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState({
    id: null,
    event_name: "...",
    event_date: "",
    event_time: "",
    location: "...",
    price: 0,
  });
  const [earlyBirdQty, setEarlyBirdQty] = useState(1);
  const total = earlyBirdQty * event.price;

  const config = {
    reference: new Date().getTime().toString(),
    email: Cookies.get("email") ? Cookies.get("email") : "example@gmail.com",
    amount: total * 100, //in kobo
    publicKey: "pk_test_7fe39e7eafe39316fcd6a35d01f0951b34f406ad",
  };

  const initializePayment = usePaystackPayment(config);

  useEffect(() => {
    document.title = "Tickets";

    getEvent();
  }, []);

  const onSuccess = (reference) => {
    buyTicket();
  };

  const onClose = () => {
    console.log("closed");
  };

  const handleSubmit = (data, formState) => {
    if (formState === "register") {
      // Registration flow
      const url = `${BASE_URL}/auth/signup`;
      axios
        .post(url, data)
        .then((response) => {
          const resData = response.data;

          if (resData.status === "FAILED") {
            toast.error(resData.message);
            return;
          }

          // Set user information in cookies
          Cookies.set("userId", resData.data.id);
          Cookies.set("name", resData.data.fullname);
          Cookies.set("email", resData.data.email);

          // Initialize
          window.location.reload();
        })
        .catch((error) => toast.error("Signup failed. Please try again later"));
    } else {
      // Login flow
      const url = `${BASE_URL}/auth/login`;
      axios
        .post(url, data)
        .then((response) => {
          const resData = response.data;

          if (resData.status === "FAILED") {
            toast.error(resData.message);
            return;
          }

          // Set user information in cookies
          Cookies.set("userId", resData.data.id);
          Cookies.set("name", resData.data.fullname);
          Cookies.set("email", resData.data.email);

          // Initialize
          window.location.reload();
        })
        .catch((error) => toast.error("Login failed. Please try again later"));
    }
  };

  const getEvent = () => {
    let url = `${BASE_URL}/event`;
    axios
      .get(url)
      .then((response) => {
        let data = response.data;

        setEvent({
          id: data.data.id,
          event_name: data.data.event_name,
          event_date: data.data.event_date,
          event_time: data.data.event_time,
          location: data.data.location,
          price: data.data.price,
        });
      })
      .catch((error) =>
        toast.error(
          "An error occured, please check your connection and try again"
        )
      );
  };

  const buyTicket = () => {
    let url = `${BASE_URL}/event/buy-ticket`;

    let userId = Cookies.get("userId");
    let email = Cookies.get("email");

    axios
      .post(url, {
        user_id: userId,
        email: email,
        event_id: event.id,
        event_name: event.event_name,
        event_date: event.event_date,
        event_time: event.event_time,
        type: "Early_bird",
        qty: earlyBirdQty,
      })
      .then((response) => {
        let data = response.data;

        if (data.status == "FAILED") {
          toast.error(data.message);
          return;
        }

        toast.success(data.message);
      })
      .catch((error) => {
        toast.error(
          "An error occured, please check your connection and try again"
        );
      });
  };

  const handleProceedClick = () => {
    if (Cookies.get("userId") == undefined) {
      setOpen(true);
    } else {
      initializePayment(onSuccess, onClose);
    }
  };

  return (
    <div className="relative flex justify-center items-center min-h-screen">
      <div className="absolute inset-0 bg-white opacity-5"></div>
      <div className="relative bg-white p-8 rounded-lg shadow-lg w-full max-w-lg my-10 z-10">
        <div className="text-center mb-6 text-black flex-col justify-left">
          <h1 className="text-2xl font-bold font-Neuehaasdisplay text-left uppercase tracking-wider">
            {event.event_name}
          </h1>
          <div className="flex mt-3 space-x-2 font-Neuehaasdisplay font-light text-sm tracking-wider">
            <img src={time} alt="time svg" className="w-5" />
            <p>
              {event.event_date
                ? new Date(event.event_date).toLocaleDateString("en-US", {
                    month: "long",
                    year: "numeric",
                  })
                : "..."}
            </p>
          </div>
          <div className="flex mt-1 space-x-2 font-Neuehaasdisplay font-light text-sm tracking-wider">
            <img src={loc} alt="time svg" className="w-5" />
            <p>{event.location}</p>
          </div>
        </div>

        <div className=" bg-gray-200 mt-4 -mx-8 text-black py-2">
          <p className="mx-8 font-Neuehaasdisplay tracking-wide capitalize font-bold text-xl">
            Tickets
          </p>
        </div>

        <div className="mt-4 text-black font-Neuehaasdisplay border border-gray-200 p-3 font-light">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-lg font-light">Early Bird</span>
              <br></br>
              <span className="text-xs font-light">
                {" "}
                (For first 100 buyers, sold until October)
              </span>
            </div>

            <div className="flex items-center space-x-1">
              <button
                className="p-1 border rounded text-gray-700"
                onClick={() =>
                  setEarlyBirdQty(earlyBirdQty > 1 ? earlyBirdQty - 1 : 1)
                }
              >
                -
              </button>
              <span className="px-3 bg-orange-400">{earlyBirdQty}</span>
              <button
                className="p-1 border rounded text-gray-700"
                onClick={() => setEarlyBirdQty(earlyBirdQty + 1)}
              >
                +
              </button>
            </div>
            <span className="font-bold">
              ₦{(event.price * earlyBirdQty).toLocaleString()}
            </span>
          </div>
        </div>

        <div className="mt-4 flex justify-end space-x-3 text-black font-Neuehaasdisplay">
          <span className="text-lg font-bold">Total</span>
          <span className="text-lg font-bold">₦{total.toLocaleString()}</span>
        </div>

        <div className="mt-6">
          <button
            className="bg-orange-500 text-white font-Neuehaasdisplay font-light px-2 py-2"
            onClick={handleProceedClick}
          >
            Proceed
          </button>
        </div>
      </div>

      <AuthModal
        open={open}
        setOpen={() => setOpen(!open)}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default TicketPage;
