import React, { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import toast from "react-hot-toast";

import { BASE_URL } from "../config/constants";
import CartItem from "./CartItem";
import { Link } from "react-router-dom";
import { MyContext } from "../context/credentialContext";

export default function CartModal({ isOpen, onClose }) {
  const [cartItems, setCartItems] = useState([]);
  const { contextValue, setContextValue } = useContext(MyContext);
  const [total, setTotal] = useState(0);

  const getItems = () => {
    if (Cookies.get("userId") == undefined) {
      setCartItems([]);
    } else {
      let userId = Cookies.get("userId");
      let url = `${BASE_URL}/cart/${userId}`;

      axios
        .get(url)
        .then((response) => {
          let data = response.data;

          setCartItems(data.data);

          if (data.data.length > 0) {
            const total = data.data.reduce((sum, item) => {
              return sum + item.price * item.qty;
            }, 0);
            setTotal(total);
          } else {
            setTotal(0);
          }
        })
        .catch((error) =>
          toast.error(
            "An error occured, please check your connection and try again"
          )
        );
    }
  };

  useEffect(() => {
    getItems();
  }, [contextValue]);

  if (!isOpen) return null;

  const handleQuantityChange = (qty, prod_id) => {
    if (qty == "" || qty == 0) {
      deleteItem(prod_id);
      return;
    }

    let userId = Cookies.get("userId");
    const url = `${BASE_URL}/cart/${prod_id}/${userId}`;

    axios
      .put(url, { qty: Number(qty) })
      .then((response) => {
        const result = response.data;
        const { status, message } = result;

        if (status == "SUCCESS") {
          getItems();
        } else {
          toast.error(message);
        }
      })
      .catch((error) => {
        toast.error(
          "An error occured, please check your connection and try again"
        );
      });
  };

  const deleteItem = (prod_id) => {
    let userId = Cookies.get("userId");
    const url = `${BASE_URL}/cart/${prod_id}/${userId}`;

    axios
      .delete(url)
      .then((response) => {
        const result = response.data;

        if (result.status == "SUCCESS") {
          setContextValue(contextValue - 1);
          getItems();
        } else {
          toast.error("Failed to delete");
        }
      })
      .catch((error) => {
        toast.error(
          "An error occured, please check your connection and try again."
        );
      });
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg max-w-lg w-full mx-3">
        <div className="p-5">
          {/* Header with Close Button */}
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold text-black">Your Cart</h2>
            <button onClick={onClose} className="text-gray-500 text-2xl">
              &times;
            </button>
          </div>

          {/* Cart Details */}
          {cartItems.length > 0 ? (
            cartItems.map((item, index) => {
              return (
                <CartItem
                  key={index}
                  item={item}
                  onChange={(e) =>
                    handleQuantityChange(e.target.value, item.prod_id)
                  }
                  remove={() => deleteItem(item.prod_id)}
                  quantity={item.qty}
                />
              );
            })
          ) : (
            <p className="text-center text-black my-5">
              You have no items in your cart
            </p>
          )}

          {/* Subtotal */}
          <div className="flex justify-between items-center border-t pt-3">
            <span className="text-black">Subtotal</span>
            <span className="text-black">NGN {total.toLocaleString()}</span>
          </div>

          {/* Continue to Checkout Button */}
          {cartItems.length > 0 && (
            <div className="text-center mt-5">
              <Link
                className="bg-green-500 text-black py-3 px-4 rounded-full"
                state={{ total }}
                to={`/checkout`}
              >
                Continue to Checkout
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
