import React from "react";
import shape from "../imgs/shape.svg";
import arrow from "../imgs/arrow.svg";
import stroke from "../svg/stroke.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function Info() {
  return (
    <>
      <div className="mx-5 md:h-[400px] py-10 flex items-left justify-start md:items-center  md:justify-center relative">
        <div className="font-bold md:text-[70px] font-Neuehaasdisplay md:inline-block hidden text-center md:leading-[65px]">
          T<span className="font-dirtyline ">H</span>E&nbsp;LOUD
          <span className="font-dirtyline">E</span>ST<br></br> R
          <span className="font-dirtyline">A</span>VE{" "}
          <motion.img
            src={shape}
            alt="shapesvg"
            animate={{ rotate: 360 }}
            transition={{ repeat: Infinity, duration: 2, ease: "linear" }}
            className="w-12 md:inline-block hidden mr-2 mb-2"
          />
          COM
          <span className="font-dirtyline">M</span>U
          <span className="font-dirtyline">N</span>ITY <br></br> IN NIGERIA.
          <img
            src={stroke}
            alt="stroke"
            className="absolute right-[30rem] rotate-[55deg] translate-y-[120%] top-[2rem] w-[150px]"
          />
        </div>

        {/* For smaller screens */}
        <div className="font-bold md:text-[70px] md:hidden text-left mt-5 font-Neuehaasdisplay  md:leading-[55px]">
          T<span className="font-dirtyline ">H</span>E&nbsp;LOUD
          <span className="font-dirtyline">E</span>ST R
          <span className="font-dirtyline">A</span>VE{" "}
          <img
            src={shape}
            alt="shapesvg"
            className="w-12 md:inline-block hidden mr-2 mb-2"
          />
          <br></br>
          COM
          <span className="font-dirtyline">M</span>U
          <span className="font-dirtyline">N</span>ITY IN NIGERIA.
        </div>
      </div>
      <div className="md:mx-2 flex-col md:flex md:justify-between my-10">
        <p className="text-left mt-5 tracking-[1px] text-xs md:text-lg leading-[15px] md:leading-[20px] font-light font-Neuehaasdisplay md:w-1/2 ml-6">
          Stunner Rave is a world-class event company powered by Nigeria’s
          vibrant energy, the largest rave movement in the country, bringing
          unforgettable experiences and championing the vibrant party culture.
        </p>

        <div className="flex items-end justify-end mr-6 mt-6 mb-6">
          <motion.div
            initial="rest"
            whileHover="hover"
            animate="rest"
            className="inline-block"
          >
            <Link
              to="/about"
              className="flex items-center md:text-lg font-Neuehaasdisplay text-sm font-light relative"
            >
              <img
                src={arrow}
                alt="arrowsvg"
                className="md:w-5 w-4 inline-block mr-2"
              />
              <span>LEARN MORE</span>

              {/* Animated line */}
              <motion.div
                className="absolute bottom-0 left-0 h-0.5 bg-white"
                variants={{
                  rest: { width: 0 },
                  hover: { width: "100%" },
                }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              />
            </Link>
          </motion.div>
        </div>
      </div>
    </>
  );
}
