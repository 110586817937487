import { Box, Modal, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#242424",
  border: "2px solid #000",
  borderRadius: 10,
  boxShadow: 24,
  p: 4,
};

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "grey",
    },
    "&:hover fieldset": {
      borderColor: "lightgrey",
    },
    "&.Mui-focused fieldset": {
      borderColor: "grey",
    },
  },
  "& .MuiInputBase-input": {
    color: "white",
  },
  "& .MuiInputLabel-root": {
    color: "lightgrey",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-root": {
    color: "grey",
  },
});

export default function AuthModal({ open, setOpen, handleSubmit }) {
  const [formState, setFormState] = useState("login");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  const validateLogin = (data) => {
    let tempErrors = {};
    if (!data.email) {
      tempErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      tempErrors.email = "Email is invalid";
    }
    if (!data.password) tempErrors.password = "Password is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (formState === "login") {
      if (validateLogin(loginData)) {
        handleSubmit(loginData, formState);
      }
    } else {
      handleSubmit(formData, formState);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        component="form"
        sx={style}
        noValidate
        onSubmit={onSubmit} // Use onSubmit for handling form submission
        className="w-full sm:max-w-sm md:max-w-md lg:max-w-lg"
      >
        <h3 className="font-semibold text-lg text-center">
          Sorry! You need to be signed in first
        </h3>

        {formState === "login" ? (
          <>
            <CustomTextField
              fullWidth
              label="Email"
              name="email"
              variant="outlined"
              value={loginData.email}
              onChange={handleLoginChange}
              error={!!errors.email}
              helperText={errors.email}
              sx={{ marginTop: 3 }}
            />
            <CustomTextField
              fullWidth
              label="Password"
              name="password"
              type="password"
              variant="outlined"
              value={loginData.password}
              onChange={handleLoginChange}
              error={!!errors.password}
              helperText={errors.password}
              sx={{ marginTop: 3 }}
            />
          </>
        ) : (
          <>
            <CustomTextField
              fullWidth
              label="Name"
              name="name"
              variant="outlined"
              value={formData.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
              sx={{ marginTop: 3 }}
            />
            <CustomTextField
              fullWidth
              label="Email"
              name="email"
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              sx={{ marginTop: 3 }}
            />
            <CustomTextField
              fullWidth
              label="Password"
              name="password"
              type="password"
              variant="outlined"
              value={formData.password}
              onChange={handleChange}
              error={!!errors.password}
              helperText={errors.password}
              sx={{ marginTop: 3 }}
            />
          </>
        )}

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ marginTop: 3 }}
        >
          Submit
        </Button>

        <div className="flex flex-row align-center justify-center mt-4">
          <p>{formState === "login" ? "Don't" : "Already"} have an account?</p>
          <p
            className="cursor-pointer ml-2 text-cream"
            onClick={() =>
              formState === "login"
                ? setFormState("register")
                : setFormState("login")
            }
          >
            {formState === "login" ? "Register" : "Login"}
          </p>
        </div>
      </Box>
    </Modal>
  );
}
